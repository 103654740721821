import ProductsTable from "../components/table/products/index";

function ShippingContainersPage(){
    return <main>
    <div className="p-5">
      <ProductsTable />
    </div>
  </main>
}

export default ShippingContainersPage;